.b-logo {
	&-link {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		height: 96px;
		margin-bottom: -8px;
		position: relative;
		width: 280px;
		z-index: z(country-selector);

		@include media(lg) {
			height: 75px;
			margin-bottom: -12px;
			width: 208px;
		}

		@include media(md) {
			height: 62px;
			margin-bottom: -4px;
			width: 193px;
		}

		@include media(sm) {
			height: 52px;
			justify-content: center;
			margin-bottom: 0;
			width: 130px;
		}

		&::after {
			content: '';
			height: 8px;
			width: 100%;

			@include media(lg) {
				height: 12px;
			}

			@include media(md) {
				height: 4px;
			}

			@include media(sm) {
				height: 2px;
			}
		}

		.b-header_stuck &,
		.b-header_slim & {
			height: 49px;
			margin: 0;
			width: 140px;

			@include media(md) {
				height: 41px;
				justify-content: center;
				width: 120px;
			}

			@include media(sm) {
				height: 37px;
				width: 90px;
			}

			&::after {
				content: none;

				@include media(lg) {
					content: '';
					height: 2px;
				}
			}
		}

		.m-skinny_nav & {
			@include media(sm) {
				height: 37px;
				width: 90px;

				&::after {
					content: none;
				}
			}
		}
	}

	svg {
		height: auto;
		max-height: 100%;
		max-width: 100%;
		width: auto;

		@include media(xl) {
			width: 280px;

			.b-header_stuck &,
			.b-header_slim & {
				width: 140px;
			}
		}
	}
}
