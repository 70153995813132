/*md

# g-title

Designed to provide same styles of title across different components.

## Usage

```scss
.component {
	@include g-title(true);
}
```

*/
@mixin g-title($size-lg: false) {
	@include g-heading_5;

	color: $color-title;
	margin-bottom: 10px;

	@if $size-lg {
		@include media(lg-up) {
			font-size: 24px;
		}
	}
}
