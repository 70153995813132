/*md
@no-stat

# Header item element in Hamburger Menu

## Usage

```scss
.component {
	@include g-header_item_menu;
}

*/

@mixin g-header_item_menu {
	border-bottom: $global-line solid $color-divider;
	font-size: 15px;
	font-weight: 600;

	&-link {
		flex-direction: row;
		padding: 15px 12px;
	}

	&-icon {
		height: 20px;
		margin: 0 10px 0 0;
		width: 20px;
	}

	&-caption {
		max-width: 100%;
	}
}
