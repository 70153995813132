.b-menu {
	&-icon {
		@include g-button_iconed;

		margin-right: 10px;
	}

	&-link {
		align-items: center;
		display: flex;
		font-size: 15px;
		font-weight: 500;

		@include media(md-down) {
			color: $color-action-obj;
		}
	}

	&-text {
		display: block;
	}
}
