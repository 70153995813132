/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/

@use 'sass:math';

@function column-width($takes: 1, $from: 12, $with-gutter: 0) {
	$column: $takes;
	$columns-total: $from;
	$gutter: $with-gutter;
	$raw-column-width: percentage(math.div($column, $columns-total));

	@if (type_of($gutter) == 'number' and $gutter > 0) {
		@return calc(#{$raw-column-width} - #{$gutter});
	} @else {
		@return $raw-column-width;
	}
}

@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default') {
	$columns-total: grid-columns($break, $grid); // stylelint-disable-line scss/at-function-named-arguments
	$gutter: if($with-gutter, grid-gutter($break, $grid), 0); // stylelint-disable-line scss/at-function-named-arguments

	@return column-width($column, $columns-total, $gutter);
}
