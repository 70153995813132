.b-header_help {
	@include g-header_item;

	.b-menu-item & {
		@include g-header_item_menu;
	}

	.l-header.m-simple & {
		font-size: 17px;
		font-weight: 600;

		@include media(md-up) {
			font-size: 18px;
		}
	}

	.l-header.m-simple &-link {
		flex-direction: row;
	}

	.l-header.m-simple &-icon {
		margin: 0 15px 0 0;
	}
}
