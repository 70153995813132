.b-promo_tiles_grid {
	@include media(md-up) {
		&.m-lg_2 &-item {
			grid-column: span 6;
		}

		&.m-lg_3 &-item {
			grid-column: span 4;
		}

		&.m-lg_4 &-item {
			grid-column: span 3;
		}
	}

	@include media(sm) {
		&.m-sm_1 &-item {
			grid-column: span 6;
		}

		&.m-sm_2 &-item {
			grid-column: span 3;
		}
	}

	&-title {
		@include g-heading_3;

		margin-bottom: 20px;
		text-align: center;

		@include media(lg-up) {
			margin-bottom: 30px;
		}
	}

	&-content {
		@include g-grid;
	}

	&-item {
		display: block;
		grid-column: span 3;

		@include media(sm) {
			grid-column: span 3;
		}
	}

	&-item_link {
		display: block;
		height: 100%;

		&:hover {
			text-decoration: none;

			.b-promo_tile-subtitle {
				text-decoration: underline;
			}
		}
	}
}
