/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/

//Hobbycraft
$color-white: #FFFFFF;
$color-burgundy: #45132C;
$color-burgundy-dark1: #230A16;
$color-burgundy-dark2: #340E21; //currently not used
$color-burgundy-light1: #52100B;
$color-burgundy-light2: #744E61; //currently not used
$color-burgundy-light3: #A28995; //currently not used
$color-pink: #C23D74;
$color-pink-dark1: #611E3A;
$color-pink-dark2: #912E57;
$color-pink-light1: #D16D97;
$color-pink-light2: #E09EBA;
$color-pink-light3: #F7E7EE;
$color-teal: #398484;
$color-teal-dark1: #1C4242;
$color-teal-dark2: #2B6363;
$color-teal-light1: #6AA3A3;
$color-teal-light2: #9CC1C1;
$color-teal-light3: #97D7D6;
$color-red: #A42015;
$color-green: #A1BD3E;
$color-green-light: #CFDFA6;
$color-green-dark: #849F23;
$color-pale-pink: #EDB8E4;
$color-orange: #F6C052;
$color-orange2: #F5E0B8;
$color-yellow: #F4F075;
$color-natural: #F5EEE4;
$color-charcoal: #2F2F2F;
$color-dark-grey: #757575;
$color-dark2-grey: #505050;
$color-aa-grey: #ACACAC;
$color-mid-grey: #D2D2D2;
$color-light-grey: #F2F2F2;
$color-light-grey2: #9F9F9F;
$color-black: #000000;
$color-dark-orange: #A34F00;
$color-blue: #96D6D5;
$color-blue2: #00D2FF;
$color-blue3: #0074CC;

// Applied colors
$color-primary: $color-charcoal;
$color-accent: $color-pink;
$color-accent-light: $color-pink-light3;
$color-accent-obj: $color-pink-light3;
//title
$color-title: $color-burgundy;
// text
$color-text: $color-black;
$color-text-dimmed: $color-dark-grey;
$color-text-disabled: $color-dark-grey;
$color-text-grey: $color-aa-grey;
$color-divider: $color-mid-grey;
$color-divider-light: $color-light-grey;
$color-divider-dimmed: $color-aa-grey;
$color-outofstock: $color-red;
$color-icon-availability: $color-teal;
$color-icon-availability-outofstock: $color-aa-grey;
$color-text-white: $color-white;
$color-inventory-message: $color-dark-orange;
$color-ideas-toggle-title: $color-white;
// actions
$color-action: $color-pink;
$color-action-obj: $color-burgundy;
$color-action-obj-text: $color-white;
$color-action-disabled: $color-mid-grey;
$color-action-disabled-light: $color-light-grey;
$color-action-dimmed: $color-pink-light1;
$color-action-dark: $color-burgundy-dark1;
$color-action-close: $color-white;
// statuses
$color-error: $color-red;
$color-error-border: $color-red;
$color-error-obj: $color-teal-light2;
$color-success: $color-green-dark;
$color-success-obj: $color-green-dark;
$color-warning: $color-yellow;
$color-warning-obj: $color-natural;
$color-info: $color-burgundy;
$color-info-obj: $color-light-grey;
// regions
$color-bg: $color-white;
$color-bg-shade: $color-light-grey;
$color-bg-shade2: $color-light-grey;
$color-bg-utility-bar: $color-burgundy;
$color-bg-header-line-1: $color-white;
$color-bg-header-line-2: $color-bg;
$color-bg-header-promo: $color-info-obj;
$color-bg-search-promo: $color-success-obj;
$color-fg-header-promo: $color-info;
$color-fg-search-promo: $color-green;
$color-bg-footer-region-1: $color-burgundy;
$color-bg-footer-region-2: $color-pale-pink;
$color-bg-footer-simplified: $color-mid-grey;
$color-bg-panel: $color-white;
$color-bg-overlay: $color-black;
$color-bg-notification-panel: $color-green;
$color-bg-switcher-container: $color-pink;
$color-bg-footer-navigation: $color-burgundy;
$color-bg-ma: $color-natural;
$color-bg-ma-club: $color-teal-light3;
$color-bg-search-result: $color-natural;
$color-bg-search-circle: $color-pale-pink;
$color-bg-qa-section: $color-natural;
$color-bg-cart-payments: $color-natural;
$color-bg-cart-badge: $color-teal;
$color-bg-tabs: $color-light-grey;
$color-border-tabs: $color-burgundy;
$color-border-tabs-inactive: $color-dark-grey;
$color-tabs: $color-charcoal;
$color-bg-counter: $color-mid-grey;
$color-bg-counter-active: $color-pink;
$color-bg-error-pages: $color-natural;
$color-bgimg-error-pages: $color-pale-pink;
$color-storelocator-icon: $color-pale-pink;
$color-bg-storelocator-search: $color-natural;
$color-storelocator-bg-icon: $color-orange;
$color-confirmation-header-bg: $color-natural;
$color-confirmation-header-bg-top: $color-orange;
$color-confirmation-header-bg-bottom: $color-pale-pink;
$color-bg-message-notifcation: $color-teal-light1;
$color-bg-account-banner: $color-teal-light1;
$color-bg-account-thumbnail: $color-pale-pink;
$color-bg-plp-slot-grey: $color-mid-grey;
$color-bg-slot-pink: $color-pale-pink;
$color-bg-newsletters-yellow: $color-yellow;
$color-bg-workshops: $color-natural;
// components
$color-bg-promo: $color-white;
$color-fg-promo: $color-red;
$color-fg-promo-hover: $color-burgundy-light1;
$color-text-promo: $color-white;
$color-bg-tag: $color-teal;
$color-bg-tag-hover: $color-teal-dark1;
$color-low-stock-text: $color-charcoal;
$color-low-stock-bg: $color-mid-grey;
$color-header-message: $color-red;
$color-plp-slot: $color-burgundy;
// forms
$color-input-border: $color-aa-grey;
$color-input-text: $color-black;
$color-select-border: $color-burgundy;
// loader
$color-loader: $color-burgundy-dark1;
$color-loader-bg: $color-white;
// links
$color-link-footer: $color-white;
$color-link-step: $color-burgundy;
$color-popup-cta: $color-white;
// border
$color-border-grey: $color-mid-grey;
$color-border-active: $color-burgundy;
$color-border-underline: $color-aa-grey;
$color-border-black: $color-black;
// scrollbar
$color-scrollbar-thumb: $color-mid-grey;
// status bar
$color-statusbar-line: $color-aa-grey;
$color-statusbar-line-active: $color-accent;
$color-share-icon: $color-burgundy;

// If you need test themes please copy colors from colors.md doc
//$theme: 'yellow';
//@if ($theme == 'yellow') {
//	$color-primary: hsl(213, 24%, 28%);
//	$color-accent: hsl(34, 99%, 70%);
// ...
