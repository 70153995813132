/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/

@use 'sass:math';

@function luminance($color) {
	$colors: (
		'red': red($color),
		'green': green($color),
		'blue': blue($color)
	);

	@each $name, $value in $colors {
		$adjusted: 0;
		$value: math.div($value, 255);

		@if ($value < 0.0393) {
			$value: math.div($value, 12.92);
		} @else {
			$value: math.div(($value + 0.055), 1.055);
			$value: math.pow($value, 2.4); // stylelint-disable-line
		}

		$colors: map-merge($colors, ($name: $value)); // stylelint-disable-line
	}

	@return (map-get($colors, 'red') * 0.2126) + (map-get($colors, 'green') * 0.7152) + (map-get($colors, 'blue') * 0.0722);
}

// Adjust color to background color
// $backgroundColor - background color
// $colorInverse - color if bg is dark. If not provided would return $color-white
// $colorNormal - color if bg is light. If not provided would return $color-text

@function adjust-color-to-bg($backgroundColor, $colorInverse: $color-white, $colorNormal: $color-text) {
	@if (luminance($backgroundColor) > 0.5) {
		@return $colorNormal;
	} @else {
		@return $colorInverse;
	}
}
