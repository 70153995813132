.b-header_workshop {
	@include g-header_item;

	@include media(md-down) {
		width: 100%;

		&-link {
			width: 100%;
		}
	}

	.m-workshops & {
		&-icon {
			height: 23px;
			margin: 0 7px 0 0;
			width: 23px;
		}

		&-link {
			color: $color-teal;
			flex-direction: row;
			font-size: 15px;
			font-weight: 500;
		}

		&-caption {
			max-width: 100%;
		}
	}
}
