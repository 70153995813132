.l-page {
	&-review {
		position: absolute;
		top: 0;
		width: 100%;
	}

	&.m-edit_mode {
		padding-top: 75px;

		@include media(md-down) {
			padding-top: 116px;
		}
	}

	&.m-transparent_header &-content {
		margin-top: -178px;

		@include media(md-down) {
			margin-top: -64px;
		}
	}

	&.m-transparent_header {
		.b-menu_bar,
		.l-header {
			background-color: transparent;
			transition: background-color $motion-fast;
		}

		.l-header-inner {
			@include media(lg-up) {
				position: relative;
				z-index: z(header);
			}
		}

		&.b-header_stuck {
			.b-menu_bar {
				@include media(lg-up) {
					background-color: $color-white;
				}
			}

			.l-header {
				background-color: $color-white;
			}
		}

		.l-header-bottom_promo,
		.b-header_category {
			display: none;
		}

		.b-menu_bar-link[aria-expanded='true'],
		.b-menu_bar-link:hover,
		.b-menu_bar-item:hover .b-menu_bar-link {
			@include media(lg-up) {
				background-color: transparent;
			}
		}

		.b-menu_bar-flyout {
			@include media(lg-up) {
				display: block;
				opacity: 0;
				transition: opacity $motion-ease;
				visibility: hidden;
			}
		}

		.l-header.m-menu_bar-active {
			@include media(lg-up) {
				background-color: $color-bg-header-line-1;
				position: relative;
				transition: background-color $motion-ease;
				z-index: z(header);
			}

			~ .b-menu_panel {
				@include media(lg-up) {
					background-color: $color-bg-panel;
					transition: background-color $motion-ease;
				}

				.b-menu_bar-flyout[aria-hidden='false'] {
					@include media(lg-up) {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}
