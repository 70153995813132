.b-menu_promo {
	display: none;

	@include media(lg-up) {
		display: flex;
		margin: 20px 0;

		@include hover-supported {
			&-link:hover &-tile_text {
				text-decoration: underline;
			}
		}

		&-tile {
			border: $global-line solid $color-divider;
			border-radius: $global-radius;
			box-shadow: $depth-1;
			margin-bottom: 10px;
			margin-right: 10px;
			text-align: center;
		}

		&-tile_img_wrapper {
			font-size: 0;
			min-height: 117px;
		}

		&-tile_img {
			border-radius: $global-radius $global-radius 0 0;
		}

		&-tile_link {
			color: $color-title;
			display: inline-block;
			margin: 8px 0;
		}

		&-tile_text {
			font-size: 13px;
			font-weight: 500;
			line-height: 16px;
		}

		&-image_link_wrapper {
			box-shadow: $depth-1;
			display: block;
			font-size: 0;
			min-height: 488px;
			position: relative;
		}

		&-image_link {
			border-radius: $global-radius;

			.g-image {
				border-radius: $global-radius;
			}
		}

		&-image_button {
			bottom: 20px;
			font-size: 12px;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
		}
	}

	.g-badge {
		font-size: 13px;
		line-height: 1.2;
	}
}
