/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/

@mixin g-button(
	$_mod: default,
) {
	@if $_mod == default {
		align-items: center;
		cursor: pointer;
		display: inline-flex;
		height: $size-input-height;
		justify-content: center;
		max-width: 100%;
		transition: $motion-ease;
		transition-property: background-color, color, border;
		user-select: none;
		vertical-align: top;
		white-space: nowrap;
	}

	@if $_mod == disabled {
		background-color: $color-action-disabled;
		border-color: $color-action-disabled;
		color: $color-text-disabled;
		pointer-events: none;
	}
}
