.b-search_toggle {
	$button-height: 42px;
	$line-color: $color-aa-grey;

	align-items: center;
	background: $color-bg-shade;
	border: $global-line solid $line-color;
	border-radius: 40px;
	color: $color-text-dimmed;
	cursor: pointer;
	display: flex;
	font-size: 13px;
	height: $button-height;
	line-height: $button-height;
	padding: 0 15px;
	text-align: left;
	white-space: nowrap;
	width: 100%;

	&-icon {
		$icon-size: 24px;

		align-items: center;
		color: $color-action-obj;
		display: flex;
		height: $icon-size;
		justify-content: center;
		margin-right: 8px;

		@include media(xl) {
			margin-right: 5px;
		}
	}
}
