/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/

@mixin g-link_hamburger(
	$_mod: default
) {
	@if $_mod == default {
		@include g-link;

		align-items: center;
		border-bottom: 1px solid $color-divider;
		cursor: pointer;
		display: flex;
		font-size: 15px;
		font-weight: 500;
		line-height: 1.5;
		min-height: 50px;
		padding: 15px 10px 15px 15px;
		text-decoration: none;
		width: 100%;
	}

	@if $_mod == highlight {
		color: $color-accent;
	}
}
