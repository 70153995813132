.l-header {
	background: $color-bg-header-line-1 50% 0 / auto 100% repeat-x;
	margin-bottom: -10px;
	padding: 14px 0 8px;

	@include media(xl) {
		min-height: 100px;
	}

	@include media(lg) {
		background-size: auto 86.5%;
		margin-bottom: -8px;
		min-height: 84px;
		padding: 16px 0 12px;
		position: relative;
		z-index: z(header);
	}

	@include media(md) {
		background-position-y: -2%;
		background-size: auto 53%;
	}

	@include media(sm) {
		background-position-y: -10%;
		background-size: auto 63%;
	}

	@include media(md-down) {
		margin-bottom: 0;
		padding: 11px 0 15px;
		position: sticky;
		top: -0.1px; // scale rounding fix for devices with 1.25 ratio
		z-index: z(header);

		.b-header_stuck &,
		.b-header_slim & {
			background-size: auto 0%;
			box-shadow: $depth-2;
			margin-bottom: 91px;
			padding: 5px 0;
		}

		.b-header_stuck & {
			position: fixed;
			top: 0;
			width: 100%;

			+ .b-sticky_marker {
				padding-top: 138px;
			}
		}

		.b-header_stuck.m-refinements_sticky &,
		.b-header_slim.m-refinements_sticky & {
			box-shadow: none;
		}
	}

	@include media(sm) {
		.m-skinny_nav & {
			background-size: auto 0%;
			box-shadow: $depth-2;
			margin-bottom: 0;
			padding: 5px 0;
		}
	}

	&.m-no_menu {
		border-bottom: $global-line solid $color-border-underline;
		margin-bottom: 0;
	}

	.m-has_dialog & {
		overflow-y: scroll;
	}

	.b-header_stuck & {
		@include media(lg-up) {
			+ .b-sticky_marker {
				padding-top: 61px;
			}
		}
	}

	.b-header_slim & {
		margin: 0;
		min-height: 0;
		padding: 0;
		position: relative;
		z-index: z(menu-bar + 1);

		@include media(md-down) {
			padding: 5px 0;
		}
	}

	&-wrapper {
		.b-header_slim & {
			position: sticky;
			top: 0;
			z-index: z(menu-bar);
		}
	}

	&-inner {
		@include g-section_holder_header;

		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 100%;

		@include media(lg-down) {
			flex-wrap: wrap;

			.b-header_stuck &,
			.b-header_slim & {
				flex-wrap: initial;
			}
		}

		@include media(sm) {
			.m-skinny_nav & {
				flex-wrap: initial;
			}
		}
	}

	&-left_mobile {
		@include media(lg-up) {
			display: none;
		}

		.b-header_stuck,
		.b-header_slim & & {
			@include media(md) {
				width: 40%;
			}
		}
	}

	&-left {
		width: 280px;

		@include media(lg) {
			width: 208px;
		}

		@include media(md) {
			margin-right: auto;
			width: 193px;

			.b-header_stuck &,
			.b-header_slim & {
				margin: 0 auto;
			}
		}

		@include media(sm) {
			align-self: start;
			max-width: calc(100% - 210px);
			width: auto;

			.b-header_stuck &,
			.m-skinny_nav &,
			.b-header_slim & {
				align-self: auto;
				margin-right: auto;
			}
		}
	}

	&-middle {
		@include media(md-down) {
			order: 1;
			width: 100%;
		}

		@include media(lg-up) {
			flex: 1;
			margin-left: 90px;
		}

		@include media(xl) {
			margin-left: 140px;
		}

		.b-header_stuck &,
		.b-header_slim & {
			display: none;
		}

		.m-skinny_nav & {
			@include media(sm) {
				display: none;
			}
		}
	}

	&-right {
		@include media(lg-up) {
			min-width: 375px;
		}

		.b-header_stuck &,
		.b-header_slim & {
			@include media(md) {
				width: 40%;
			}

			@include media(lg-up) {
				min-width: auto;
			}
		}
	}

	// Styles for header on checkout page
	&.m-simple {
		background: $color-bg-shade;
		padding: 24px 0;

		@include media(md-down) {
			position: static;
		}
	}

	&.m-simple &-inner {
		@include g-section_holder;

		flex-wrap: nowrap;
	}

	&.m-simple &-left {
		max-width: 208px;

		@include media(md-down) {
			align-self: center;
			width: 160px;
		}
	}

	&.m-simple &-right {
		min-width: auto;

		@include media(md-down) {
			max-width: 159px;
			text-align: end;
		}
	}
}
