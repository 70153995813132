.b-logo_sticky {
	.b-header_stuck &,
	.b-header_slim & {
		@include media(lg-up) {
			@include g-section_holder_sticky;

			height: 0;
			left: 0;
			position: fixed;
			right: 0;
			top: 6px;
			width: auto;
			z-index: z(header-actions-sticky);
		}
	}

	.b-header_slim & {
		@include media(lg-up) {
			position: absolute;
		}
	}
}
