/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
$global-radius: 4px;
$global-line: 1px;
$global-content-width-min: 360px;
$global-content-width-max: 1600px;
$global-site-width-max: 1920px; // used on Header and HP fullbleed promo
$footer-content-width-max: 1360px;
$plp-content-width-max: 1360px;
$input-radius: 3px;
$controls-radius: 40px;
$controls-radius-small: 35px;
$content-limit-xl: 1440px;
$content-limit-lg: 984px;
$search-box-width-max: 1400px;

// Z-depth
$depth-0: none;
$depth-1: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
$depth-2: 0 4px 8px 0 hsla(0, 0%, 0%, 0.1);
$depth-2-no-top: 0 10px 10px rgba(0, 0, 0, 0.35);
$depth-3: 0 3px 15px hsla(0, 0%, 0%, 0.35);
$depth-4: 0 0 4px 0 rgba($color-black, 0.08);

// Motion
$motion-fast: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
$motion-ease: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
$motion-ease-popups: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
