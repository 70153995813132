.b-find_store {
	@include g-header_item($_width_sm: 10vw);

	&-caption {
		.b-header_stuck & {
			max-width: 50px;
		}

		.m-skinny_nav & {
			@include media(sm) {
				max-width: 50px;
			}
		}
	}

	.b-menu-item & {
		@include g-header_item_menu;

		&-icon {
			height: 24px;
			width: 24px;
		}
	}
}
