/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/

@use 'sass:math';

@mixin g-spinner($size: 2em, $reverse: false) {
	animation: 1s linear infinite rotate;
	border: #{math.div($size, 8)} solid $color-info;
	border-left-color: $color-info-obj;
	border-radius: 50%;
	border-top-color: $color-info-obj;
	content: 'Loading…';
	display: block;
	height: $size;
	margin: auto;
	pointer-events: none;
	position: relative;
	text-indent: -9999em;
	width: $size;
}
