/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/

@mixin g-heading_1 {
	font-size: 48px;
	font-weight: 700;
	line-height: 1.25;
}

@mixin g-heading_2 {
	font-size: 40px;
	font-weight: 700;
	line-height: 1.25;
}

@mixin g-heading_3 {
	font-size: 32px;
	font-weight: 700;
	line-height: 1.25;
}

@mixin g-heading_4 {
	font-size: 24px;
	font-weight: 700;
	line-height: 1.25;
}

@mixin g-heading_4_1 {
	font-size: 24px;
	font-weight: 500;
	line-height: 1.25;
}

@mixin g-heading_5 {
	font-size: 20px;
	font-weight: 700;
	line-height: 1.25;
}

@mixin g-heading_6 {
	font-size: 17px;
	font-weight: 700;
	line-height: 1.25;
}
