/*md

# b-promo_tiles_grid

This is special type of banners that is provided as single component and mostly
used as set of tiles with links to categories or subcategories.

## Example

```html_example
<section class="b-promo_tiles_grid">
	<h2 class="b-promo_tiles_grid-title">
		Top Categories
	</h2>
	<div class="b-promo_tiles_grid-content">
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-1')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-1.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							New in
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-2')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-2.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Women
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-3')$"
			>
				<figure class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-3.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
					<figcaption class="b-promo_tile-caption">
						<p class="b-promo_tile-title">
							Inspiration
						</p>
					</figcaption>
				</figure>
			</a>
		</div>
		<div class="b-promo_tiles_grid-item">
			<a
				class="b-promo_tiles_grid-item_link"
				href="$url('Search-Show', 'cgid', 'subcategory-2-4')$"
			>
				<div class="b-promo_tile">
					<picture class="b-promo_tile-picture">
						<img
							loading="lazy"
							src="../images/guide/examples/banner-3x4-4.jpg?$staticlink$"
							alt="Just dummy image."
							width="336"
							height="420"
						/>
					</picture>
				</div>
			</a>
		</div>
	</div>
</section>
```

*/

.b-promo_tile {
	border-radius: $global-radius;
	box-shadow: $depth-1;
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100%;
	overflow: hidden;
	position: relative;

	&.m-caption_below &-caption {
		grid-row: 2 / 2;
		padding: 10px;

		@include media(lg-up) {
			padding: 10px 15px;
		}
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(3, 2));

		grid-column: 1 / 2;
		grid-row: 1 / 2;

		img {
			@include g-image_container(_img);
		}
	}

	&-caption {
		display: flex;
		flex-direction: column;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		height: 100%;
		justify-content: flex-start;
		padding: 40px 15px;
		width: 100%;
		z-index: 1;

		@include media(sm) {
			padding-bottom: 40px;
			padding-top: 40px;
		}

		.b-button { // Case with button inside
			margin-top: 15px;
		}
	}

	&-title {
		font-size: 20px;
		font-weight: 700;
		line-height: 25px;
		margin-bottom: 10px;

		@include media(lg-up) {
			font-size: 24px;
			line-height: 30px;
		}
	}

	&-subtitle {
		font-size: 13px;
		font-weight: 500;

		@include media(lg-up) {
			font-size: 15px;
		}
	}

	&-link {
		font-size: 13px;
		margin-top: 5px;

		@include media(lg-up) {
			font-size: 15px;
		}
	}
}
