@use 'sass:math';

@mixin b-menu_bar-link--active {
	color: $color-action-obj;
	font-weight: 600;
	position: relative;
	text-decoration: underline;
	z-index: 1;
}

@include media(lg-up) {
	.b-menu_bar {
		$_root: &;
		$column-gap: 20px;

		background-color: $color-bg;
		border-bottom: $global-line solid $color-divider;
		display: flex;
		position: relative;
		z-index: z(menu-bar);

		&-container {
			@include g-section_holder_sticky;

			max-width: min(100%, 1440px);
		}

		&-inner {
			align-items: center;
			display: flex;
		}

		&-item {
			max-width: var(--item-width);
			padding: 10px 0;

			.b-header_stuck &,
			.b-header_slim & {
				&.m-sticky_hidden,
				&.m-sticky_overflow {
					display: none;
				}
			}

			&.m-mobile {
				display: none;
			}
		}

		&-link {
			align-items: center;
			color: inherit;
			display: flex;
			font-size: 12px;
			font-weight: 500;
			line-height: 1.1;
			min-height: 40px;
			padding: 0 12px;
			position: relative;
			text-decoration: none;

			&.m-highlight {
				color: $color-accent;
			}

			&[aria-expanded='true'] {
				@include b-menu_bar-link--active;
			}

			&:hover {
				@include hover-supported {
					@include b-menu_bar-link--active;
				}
			}

			.b-header_slim & {
				padding: 0 11px;
			}
		}

		&-item &-link {
			text-align: center;
		}

		&-item + &-item &-link {
			&::before {
				background-color: $color-divider;
				content: '';
				height: 24px;
				left: 0;
				margin-top: -12px;
				position: absolute;
				top: 50%;
				width: 1px;
			}
		}

		&-link_text {
			&::before {
				content: attr(data-name);
				display: block;
				font-weight: 600;
				height: 0;
				overflow: hidden;
				visibility: hidden;
			}
		}

		&-link_highlight {
			margin-left: 4px;
		}

		&-link_icon_highlight {
			svg {
				width: 18px;
			}
		}

		&-flyout {
			background-color: $color-bg;
			border-bottom: $global-line solid $color-divider;
			border-top: $global-line solid $color-divider;
			box-shadow: 0 100vh 0 100vh rgba($color-bg-overlay, 0.5);
			color: $color-text;
			display: none;
			left: 0;
			max-height: 70vh;
			overflow-y: auto;
			position: absolute;
			right: 0;
			top: 100%;
			z-index: 0;

			@include media(lg) {
				height: var(--flyout-height-l);
			}

			@include media(xl) {
				height: var(--flyout-height-xl);
			}

			&[aria-hidden='false'] {
				display: block;
			}

			&::-webkit-scrollbar {
				height: 0;
				width: 0;
			}
		}

		&-flyout_inner {
			@include g-section_holder_header;

			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			position: relative;
			width: 100%;

			@include media(lg) {
				padding-left: 0;
			}

			.content-asset {
				width: 100%; // need to fix this content asset wrapper for flyout with customHTML asset
			}
		}

		&-flyout_column {
			width: 100%;
		}

		&-flyout_promo {
			display: none;
			padding-bottom: 20px;
			width: 396px;

			picture {
				@include g-image_container(_container, 75%);
			}

			img {
				@include g-image_container(_img);
			}
		}

		&-columns {
			border-right: $global-line solid transparent;
			max-width: 320px;
			min-height: 300px;
			position: relative;
			width: 25%;
			z-index: 1;

			&.m-custom_menu {
				width: 100%;
			}
		}

		&-flyout[aria-hidden='false'] &-columns {
			border-color: $color-divider;
		}

		/* stylelint-disable */
		.m-columns_view & {
			&-columns {
				border: 0;
				display: grid;
				grid-auto-columns: min(17vw, 275px);
				height: auto;
				flex: 1;
				margin: 0 -#{math.div($column-gap, 2)};
				max-width: none;
				overflow: hidden;
				padding-top: 20px;
				position: relative;
				width: auto;

				&::after {
					background: $color-bg;
					content: '';
					height: 100vh;
					position: absolute;
					right: 0;
					top: 0;
					width: $global-line + 1px;
				}
			}

			&-column {
				display: inline-block;
				padding: 0 #{math.div($column-gap, 2)};
				position: relative;
				vertical-align: top;
				width: 100%;

				&::after {
					background: $color-divider;
					content: '';
					height: 100vh;
					position: absolute;
					right: 0;
					top: -40px;
					width: $global-line;
				}

				.b-menu_promo & {
					display: block;
					width: 201px;

					&::after {
						content: none;
					}
				}

				.b-menu_item+.b-menu_item.m-has-submenu,
				.b-menu_item.m-has-submenu+.b-menu_item {
					margin-top: 25px;
				}
			}

			&-flyout_inner {
				@include media(lg) {
					padding-left: 10px;
				}

				.b-menu_promo {
					padding-left: $column-gap;
				}
			}
		}

		.m-columns_view.m-columns_5 &-columns {
			grid-template-columns: repeat(5, 1fr);
		}

		.m-columns_view.m-columns_4 &-columns {
			grid-template-columns: repeat(4, 1fr);
			max-width: calc(80% + #{$column-gap * 0.8});
		}

		.m-columns_view.m-columns_3 &-columns {
			grid-template-columns: repeat(3, 1fr);
			max-width: calc(60% + #{$column-gap * 0.6});
		}

		.m-columns_view.m-columns_2 &-columns {
			grid-template-columns: repeat(2, 1fr);
			max-width: calc(40% + #{$column-gap * 0.4});
		}

		.m-columns_view.m-columns_1 &-columns {
			grid-template-columns: repeat(1, 1fr);
			max-width: calc(20% + #{$column-gap * 0.2});
		}

		.m-columns_view.m-border &-columns {
			&::after {
				content: none;
			}
		}

		/* stylelint-enable */
	}
}

@include media(xl) {
	.b-menu_bar {
		&-flyout_promo {
			display: block;
		}

		&-inner {
			.b-header_stuck &,
			.b-header_slim & {
				margin: 0 235px 0 145px;
				overflow: hidden;
			}

			.b-header_slim & {
				margin-right: 250px;
			}
		}

		&-link {
			font-size: 14px;
			line-height: 1.45;

			.b-header_stuck &,
			.b-header_slim & {
				font-size: 12px;
				line-height: 1.1;
			}
		}
	}
}

//custom media query to avoid trimmed menu items between lg and xl
@media screen and (min-width: #{$break-xl}) and (max-width: 1410px) {
	.b-menu_bar {
		&-link {
			.b-header_stuck &,
			.b-header_slim & {
				font-size: 11px;
			}
		}
	}
}

@include media(md-down) {
	.b-menu_bar-inner {
		display: flex;
		flex-direction: column;
		margin: 0;
	}

	.b-menu_bar-flyout_close,
	.b-menu_bar-flyout_promo {
		display: none;
	}

	.b-menu_bar-link {
		@include g-link_hamburger;

		&.m-highlight {
			color: $color-accent;
			padding-left: 13px;
		}

		&.m-has-submenu {
			&::after {
				border: solid $color-primary;
				border-width: 0 2px 2px 0;
				content: '';
				display: inline-block;
				height: 12px;
				margin-left: auto;
				margin-right: 5px;
				transform: rotate(-45deg);
				vertical-align: middle;
				width: 12px;
			}

			&.m-highlight {
				&::after {
					border-color: $color-accent;
				}
			}

			&.m-workshops {
				padding-left: 12px;

				&::after {
					border-color: $color-teal;
				}
			}
		}
	}

	.b-menu_bar-link_highlight {
		font-weight: 600;
		line-height: 1.5;
		margin-left: 6px;
	}
}
