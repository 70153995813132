.b-search_button {
	@include g-header_item;

	display: none;

	.b-header_stuck &,
	.b-header_slim & {
		display: block;
	}

	.m-skinny_nav & {
		@include media(sm) {
			display: block;
		}
	}
}
