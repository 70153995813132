/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/

// Breakpoints
$break-xl: 1367px;
$break-md-large: 1200px;
$break-lg: 1024px;
$break-md: 768px;

// config for `media()` mixin
$media: (
	sm: 'screen and (max-width: #{$break-md - 1})',
	md: 'screen and (min-width: #{$break-md}) and (max-width: #{$break-lg - 1})',
	md-large: 'screen and (min-width: #{$break-md}) and (max-width: #{$break-md-large})',
	lg: 'screen and (min-width: #{$break-lg}) and (max-width: #{$break-xl - 1})',
	lg-large: 'screen and (min-width: #{$break-md-large}) and (max-width: #{$break-xl - 1})',
	xl: 'screen and (min-width: #{$break-xl})',
	md-up: 'screen and (min-width: #{$break-md})',
	md-down: 'screen and (max-width: #{$break-lg - 1})',
	md-large-down: 'screen and (max-width: #{$break-md-large})',
	lg-up: 'screen and (min-width: #{$break-lg})',
	lg-large-up: 'screen and (min-width: #{$break-md-large})',
	lg-down: 'screen and (max-width: #{$break-xl - 1})',
	sm_landscape: 'screen and (orientation: landscape) and (max-width: #{$break-md - 1})'
);

@import 'breakpoints_tools';
