/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/

@mixin g-section_holder_header {
	margin: 0 auto;
	max-width: 1418px;
	padding-left: 20px;
	padding-right: 20px;

	@include media(md-down) {
		padding-left: 15px;
		padding-right: 15px;
	}
}
