.b-header_button {
	align-items: center;
	color: $color-action-obj;
	display: flex;
	flex-direction: column;
	font-size: 11px;
	font-weight: 500;

	@include media(md) {
		font-size: 14px;

		.b-header_stuck &,
		.b-header_slim & {
			font-size: 11px;
		}
	}

	&-icon {
		@include g-button_iconed;

		margin-bottom: 6px;

		@include media(md-down) {
			.b-header_stuck &,
			.b-header_slim & {
				height: 24px;
				margin-bottom: 1px;
				width: 24px;
			}
		}

		@include media(sm) {
			.m-skinny_nav & {
				height: 24px;
				margin-bottom: 1px;
				width: 24px;
			}
		}
	}
}
