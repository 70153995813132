.b-header_utility {
	background-color: $color-bg-utility-bar;
	color: $color-text-white;
	display: none;
	font-size: 13px;
	font-weight: 400;
	position: relative;

	@include media(lg-down) {
		font-size: 11px;
	}

	&.m-visible {
		display: flex; // to fix child align-items bug with min-height in IE
	}

	.m-has_dialog & {
		overflow-y: scroll; // we need a wrapper to handle scroll / no scroll content width bumping
	}

	.m-no_header_promo & {
		@include media(sm) {
			display: none;
		}
	}

	&-inner {
		@include g-section_holder_header;

		align-items: center;
		display: flex;
		justify-content: center;
		min-height: 30px;
		padding-bottom: 5px;
		padding-top: 5px;
		width: 100%;

		@include media(sm) {
			padding-right: 35px;
		}
	}

	&-item {
		text-align: center;
		width: 100%;
	}

	&-button {
		cursor: pointer;
		margin-top: -10px;
		position: absolute;
		right: 15px;
		top: 50%;

		@include media(md-up) {
			right: 5px;
		}
	}
}

.b-header_utility_carousel {
	margin: 0 auto;

	&.b-hero_carousel {
		@include media(md-up) {
			max-width: 80%;
			padding: 0 20px;
		}
	}

	&.m-one_item {
		justify-content: center;
	}

	&-item {
		min-width: 100%;
		text-align: center;
		width: 100%;
	}

	&.m-fade &-item {
		animation: fade-in var(--fade-delay) linear;
		opacity: 0;
		transition-property: opacity !important; // stylelint-disable-line

		&.m-current {
			animation: fade-out var(--fade-delay) linear;
			opacity: 1;
		}
	}

	&-ctrl {
		cursor: pointer;
		left: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		@include media(sm) {
			display: none;
		}

		&.m-next {
			left: auto;
			right: 0;
		}

		svg {
			height: 17px;
			width: 17px;
		}

		&[disabled] {
			cursor: default;
		}
	}
}
