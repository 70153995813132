.b-header_actions {
	color: $color-action-obj;
	display: flex;

	&-search {
		max-width: 236px;
		width: 100%;

		@include media(md) {
			max-width: 361px;
		}

		@include media(md-down) {
			margin: 15px auto 0;
		}

		@include media(xl) {
			max-width: 344px;
		}
	}

	&-hamburger {
		@include media(sm) {
			margin-right: 12px;
		}

		@include media(md) {
			margin-right: 20px;
		}
	}

	&-account_sm {
		margin-right: 12px;

		@include media(md-up) {
			display: none;
		}

		.b-header_stuck &,
		.b-header_slim & {
			display: none;
		}

		.m-skinny_nav & {
			@include media(sm) {
				display: none;
			}
		}
	}

	/* stylelint-disable no-descending-specificity */
	&-storelocator {
		.l-header-left_mobile & {
			margin-left: 3px;
			margin-right: 15px;

			@include media(md-up) {
				display: none;
			}

			.b-header_stuck &,
			.m-skinny_nav &,
			.b-header_slim & {
				@include media(sm) {
					display: none;
				}
			}
		}

		.l-header-right & {
			@include media(sm) {
				display: none;

				.b-header_stuck &,
				.m-skinny_nav &,
				.b-header_slim & {
					display: block;
				}
			}
		}

		.b-menu-item & {
			margin: 0;
		}
	}

	&-account {
		@include media(lg-up) {
			position: relative;
		}
	}

	&-account_inner,
	&-wishlist,
	&-workshops {
		@include media(sm) {
			display: none;
		}

		.b-header_stuck &,
		.b-header_slim & {
			display: none;
		}
	}

	&-wishlist {
		.b-header_slim & {
			display: block;
		}
	}

	&-help {
		.b-header_slim & {
			display: none;
		}
	}

	&-help,
	&-storelocator,
	&-workshops,
	&-wishlist,
	&-minicart {
		margin-left: 15px;

		@include media(md-up) {
			margin-left: 20px;
		}

		@include media(xl) {
			margin-left: 25px;
		}

		.b-header_stuck &,
		.b-header_slim & {
			margin-left: 12px;

			@include media(md-up) {
				margin-left: 19px;
			}
		}

		.m-skinny_nav & {
			@include media(sm) {
				margin-left: 12px;
			}
		}
	}
}

.l-header-right .b-header_actions {
	justify-content: flex-end;
	width: 100%;
}
