/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/

@mixin g-snap_scroll($direction: x, $type: mandatory) {
	display: flex;
	overflow: hidden;
	overflow-behavior: contain; // stylelint-disable-line
	-webkit-overflow-scrolling: touch;
	overscroll-behavior-x: none;
	overflow-scrolling: touch; // stylelint-disable-line
	-ms-overflow-style: none; // IE
	scroll-behavior: smooth;
	-ms-scroll-chaining: none; // stylelint-disable-line
	scrollbar-width: none; // FF

	@if ($direction == 'y') {
		flex-direction: column;
		overflow-y: auto;
		scroll-snap-type: y $type;
	} @else {
		overflow-x: auto;
		scroll-snap-type: x $type;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}
