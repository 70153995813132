:root {
	--fade-delay: 2000;
}

// General site styles
// Be very carefully since it affects all elements and styles!
html {
	background: $color-bg;
	color: $color-text;
	direction: ltr;
	font: #{$size-font}/#{$size-line} $font-sans;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-synthesis: none;
	scroll-behavior: auto;
	-ms-text-size-adjust: 100%; // stylelint-disable-line
	-webkit-text-size-adjust: 100%; // stylelint-disable-line

	&.m-has_dialog {
		body {
			overflow: hidden;
		}
	}
}

body {
	box-sizing: border-box;
	margin: 0;
	min-width: $global-content-width-min;
	overflow-y: scroll;
	padding: 0.01px 0 0; // Android UI status bar overlap browser window. Reproducible on Samsung S9 Chrome
}

a {
	color: inherit;
	text-decoration: none;

	// We should include hover on global elements and elements
	// otherwise iOS will fire hover on first tap, click on second
	// this is critical for components like back to top button
	@include hover-supported {
		&:hover {
			text-decoration: underline;
		}
	}
}

button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	padding: 0;
}

img {
	overflow: hidden;
}

// Use to show anchor elements down below sticky header
// Sticky header height(60px and 100px) + 20px indent
:target {
	&::before {
		content: '';
		display: block;
		height: 80px;
		margin-top: -80px;
		visibility: hidden;
		z-index: -1;

		@include media(lg) {
			height: 120px;
			margin-top: -120px;
		}
	}
}

// Hide element on screen, but make it accessible for AT
.b-sr_only {
	@include hide(visually);
}

.m-dot {
	margin-left: -0.27em;
}

.m-hide {
	display: none !important; // stylelint-disable-line
}

/* stylelint-disable */
.osano-cm-button--type_denyAll,
.osano-cm-link--type_manage,
.osano-cm-close {
	display: none;
}

[data-af-custom-fonts="af-creatives-text"] { 
	font-family: $font-sans !important;
}
/* stylelint-enable */
