.b-header_message {
	font-size: 13px;
	line-height: 1.45;
	padding: 13px 30px 14px;
	text-align: center;
	visibility: visible;

	@include media(sm) {
		padding-bottom: 12px;
		padding-top: 12px;
	}

	&.m-error {
		background-color: rgba($color-header-message, 0.15);
		color: $color-header-message;
	}

	&-inner {
		margin: 0 auto;
		max-width: 675px;
		width: 100%;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}
