.b-nav_aux {
	@include g-section_holder_header;

	color: $color-text-dimmed;
	margin-bottom: 10px;
	margin-top: 10px;

	@include media(md-up) {
		margin-bottom: 16px;
		margin-top: 16px;
	}
}
