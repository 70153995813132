.b-header_login_promt {
	background-color: $color-bg-ma;
	border: $global-line solid $color-input-border;
	border-radius: 2px;
	box-shadow: 0 2px 7px rgba($color-black, 0.25);
	color: $color-primary;
	font-size: 13px;
	padding: 5px 7px 5px 5px;

	&-close {
		color: $color-text-dimmed;
		cursor: pointer;
		margin-left: 6px;
	}
}
