/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/

/* stylelint-disable */
@function rh($parameter) {
	$step: $size-rhythm-step;
	$result: null;

	@if (type-of($parameter) == list) {
		@for $i from 1 through length($parameter) {
			$item: nth($parameter, $i);
			$el: 0;

			@if $item != 0 {
				$el: nth($parameter, $i) * $step * 1px;
			}

			$result: join($result, $el);
		}
	} @else {
		$result: $parameter * $step * 1px;
	}

	@return $result;
}
/* stylelint-enable */
