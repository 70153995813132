/*md

# g-section_holder_footer

Since footer is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold footer of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-footer-inner {
	background: green;

	&-footer {
		@include g-section_holder_footer;
	}
}
```
*/

@mixin g-section_holder_footer {
	margin: 0 auto;
	max-width: $footer-content-width-max;
	padding-left: grid-margin(xl);
	padding-right: grid-margin(xl);

	@include media(lg) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@include media(md) {
		padding-left: grid-margin(md);
		padding-right: grid-margin(md);
	}

	@include media(sm) {
		padding-left: grid-margin(sm);
		padding-right: grid-margin(sm);
	}
}
