/*md

# b-breadcrumbs

Breadcrumbs navigation is represented as a list of links.

The last item of breadcrumbs list (e.g. "Level 4" link) is hidden. It links to the current page and is only needed for accessibility purposes.

```html_example
<nav class="b-breadcrumbs" aria-label="Breadcrumbs">
    <ul class="b-breadcrumbs-list">
    	<li class="b-breadcrumbs-item">
    	    <a class="b-breadcrumbs-link" href="">Level 1</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	        <use href="#arrow-forward"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Level 2</a>
    	</li>
    	<li class="b-breadcrumbs-item">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	        <use href="#arrow-forward"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Level 3</a>
    	</li>
    	<li class="b-breadcrumbs-item m-last">
    	    <svg class="b-breadcrumbs-icon" aria-hidden="true" width="9" height="9" focusable="false">
    	       <use href="#arrow-forward"></use>
    	    </svg>
    	    <a class="b-breadcrumbs-link" href="">Current Page</a>
    	</li>
    </ul>
</nav>
*/

.b-breadcrumbs {
	color: $color-text-disabled;
	overflow: hidden;

	&-list {
		display: flex;
	}

	&-item {
		align-items: center;
		display: flex;
		flex: 0 100000 auto;
		line-height: 1.2;
		margin-left: 6px;
		min-width: 40px;
		overflow: hidden;
		white-space: nowrap;

		&.m-first,
		&.m-full {
			flex-shrink: 0;
		}

		&.m-first {
			margin: 0;
			min-width: 39px;
		}

		&.m-last {
			flex-shrink: 1;
			font-weight: 500;
			margin-right: -1em;
		}
	}

	&-icon {
		flex: 0 0 auto;
	}

	&-link {
		color: $color-text-dimmed;
		display: block;
		flex: 0 1 auto;
		font-size: 13px;
		line-height: normal;
		margin-left: 6px;
		overflow: hidden;
		text-decoration: underline;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include media(md-down) {
			text-decoration: none;
		}

		&:hover {
			text-decoration: none;
		}

		&[aria-current='page'] {
			color: inherit;
			cursor: default;
			margin-right: 1em;
			pointer-events: none; // it should be link for ARIA and Schema.org requirements
			text-decoration: none;
		}
	}

	&-item.m-first &-link {
		margin: 0;
	}

	&-last {
		color: $color-text-dimmed;
		font-size: 13px;
		line-height: 1;

		@include media(lg-up) {
			font-weight: 600;
		}

		@include media(md-down) {
			color: $color-primary;
		}
	}

	// Breadcrumbs on mobile PDP
	.l-pdp & {
		@include media(sm) {
			&-item {
				display: none;

				&.m-full_parent,
				&.m-full {
					display: flex;
					flex-shrink: initial;
				}

				&.m-full_parent {
					margin-left: 0;
				}

				&.m-full_parent .b-breadcrumbs-icon {
					display: none;
				}

				&.m-full_parent .b-breadcrumbs-link {
					margin-left: 0;
				}
			}
		}
	}
}
