.b-badges {
	align-items: flex-start;
	display: flex;
	flex-flow: column;
	left: 20px;
	position: absolute;
	top: 20px;
	width: calc(100% - 35px);
	z-index: 0;

	@include media(md-down) {
		width: calc(100% - 20px);
	}

	.m-black-friday & {
		margin-top: 5px;
	}

	&-wrapper {
		margin-top: 4px;

		.m-black-friday & {
			margin-top: 0;
		}
	}

	&-item {
		background-color: $color-action;
		border-radius: 2px;
		color: $color-white;
		display: block;
		font-size: 13px;
		font-weight: 500;
		line-height: 1.25;
		padding: 4px 8px;
		word-break: break-word;

		.m-black-friday & {
			background-color: $color-black !important; // stylelint-disable-line
			color: $color-blue2 !important; // stylelint-disable-line
		}
	}

	&-item + &-item {
		margin-top: 4px;
	}

	.b-product_tile &,
	.b-carousel & {
		left: 5px;
		max-width: calc(100% - 68px);
		top: 0;

		@include media(md-down) {
			max-width: calc(100% - 40px);
		}
	}

	.b-wishlist_tile & {
		left: 10px;
		max-width: calc(100% - 68px);
		top: 10px;

		@include media(md-down) {
			max-width: calc(100% - 40px);
		}
	}

	.b-product_gallery & {
		flex-flow: row;
	}

	.b-product_gallery &-wrapper {
		margin-top: 0;

		@include media(md-down) {
			margin-top: 8px;
		}

		&.m-right {
			margin-left: auto;
		}
	}

	.b-product_gallery &-item {
		font-size: 15px;
		line-height: 1;
		padding: 8px;
	}
}
