@include media(lg-up) {
	.b-header_actions_sticky {
		.b-header_stuck &,
		.b-header_slim & {
			@include g-section_holder_sticky;

			animation: fade-in $motion-ease;
			color: $color-action-obj;
			height: 0;
			left: 0;
			position: fixed;
			right: 0;
			top: 8px;
			width: auto; // need to not overlap main navigation and logo
			z-index: z(header-actions-sticky);

			@include media(lg) {
				top: 14px;
			}
		}

		.b-header_slim & {
			position: absolute;
		}
	}
}
