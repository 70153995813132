/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/

@mixin g-accordion(
	$_: root,
	$_mod: default
) {
	@if $_ == root {
	}

	@if $_ == _item {
	}

	@if $_ == _control {
		@if $_mod == default {
			align-items: center;
			cursor: pointer;
			display: flex;
			font-size: 15px;
			font-weight: 500;
			justify-content: space-between;
			padding: 12px 15px;
			text-align: left;
			width: 100%;
		}

		@if $_mod == hover {
			color: $color-action;
		}
	}

	@if $_ == _content {
		@if $_mod == default {
			height: 0;
			opacity: 0;
			overflow: hidden;
			position: relative;
			transition: $motion-fast;
			transition-property: height, opacity;
			visibility: hidden;
		}

		@if $_mod == expanded {
			height: auto;
			opacity: 1;
			visibility: visible;
		}
	}

	@if $_ == _content_inner {
		overflow: hidden;
		padding: 0 15px 20px;
	}
}
