@include media(lg-up) {
	.b-menu_subpanel-container {
		&.m-level_2,
		&.m-level_3 {
			display: none;
		}
	}
}

@include media(md-down) {
	.b-menu_subpanel {
		display: flex;
		height: 100%;
		transition: transform $motion-ease-popups;

		&.m-active_level_1 {
			transform: translateX(0);
		}

		&.m-active_level_2 {
			transform: translateX(-100%);
		}

		&.m-active_level_3 {
			transform: translateX(-200%);
		}

		&.m-active_level_4 {
			transform: translateX(-300%);
		}

		&-container {
			min-width: 100%;
			overflow-y: auto;
		}

		&-container.m-level_1 &-content.m-level_2_content,
		&-container.m-level_2 &-content.m-level_3_content { // Hide 3rd level submenu if it inside level 1 panel
			display: none;
		}
	}
}
