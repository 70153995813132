@use 'sass:math';

.b-minicart_icon {
	@include g-header_item;

	&-link {
		position: relative;
	}

	&-icon {
		@include media(lg) {
			.b-header_stuck &,
			.b-header_slim & {
				margin-right: 6px;
			}
		}
	}

	&-qty {
		$size: 16px;

		background-color: $color-accent;
		border-radius: #{math.div($size, 2)};
		color: adjust-color-to-bg($color-accent);
		font-size: 11px;
		font-weight: 500;
		height: $size;
		line-height: $size;
		min-width: $size;
		padding: 0 3px;
		position: absolute;
		right: 2px;
		text-align: center;
		top: 0;

		@include media(md-up) {
			right: 10px;
		}

		.b-header_stuck &,
		.b-header_slim & {
			@include media(md) {
				right: 2px;
			}

			@include media(lg) {
				left: 12px;
				right: auto;
				top: -3px;
			}
		}
	}
}
