/*md
@no-stat

# Header item

This mixin is useful for header items with icon

## Usage

```scss
.component {
	@include g-header_item();
}

*/

@mixin g-header_item($_width_sm: 50px) {
	font-size: 11px;
	font-weight: 500;

	@include media(md-up) {
		font-size: 13px;
	}

	@include media(md) {
		.b-header_stuck &,
		.b-header_slim & {
			font-size: 11px;
		}
	}

	&-icon {
		@include g-button_iconed;

		margin-bottom: 7px;

		@include media(md-up) {
			height: 28px;
			width: 28px;
		}

		.b-header_stuck &,
		.b-header_slim & {
			@include media(xl) {
				height: 26px;
				margin-bottom: 3px;
				width: 26px;
			}

			@include media(lg) {
				margin: 0 4px 0 0;
			}

			@include media(lg-down) {
				height: 24px;
				width: 24px;
			}

			@include media(md-down) {
				margin-bottom: 1px;
			}
		}

		.m-skinny_nav & {
			@include media(sm) {
				height: 24px;
				margin-bottom: 1px;
				width: 24px;
			}
		}
	}

	&-link {
		align-items: center;
		color: $color-action-obj;
		display: flex;
		flex-direction: column;
		line-height: 16px;
		transition: $motion-ease;
		transition-property: color, fill;

		@include hover-supported {
			&:hover {
				opacity: 0.8;
			}
		}

		.b-header_stuck &,
		.b-header_slim & {
			@include media(lg) {
				flex-direction: row;
			}
		}
	}

	&-caption {
		@include line-clamping($lines: 1);

		max-width: 90px;
		word-break: break-all;

		@include media(sm) {
			max-width: $_width_sm;
		}
	}
}
