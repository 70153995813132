.b-menu_panel {
	@include media(lg-up) {
		position: sticky;
		top: -0.1px; // scale rounding fix on monitors with 1.25 ratio
		z-index: z(menu-bar);

		.b-header_stuck &,
		.b-header_slim & {
			box-shadow: $depth-2;
		}

		.b-header_stuck & {
			position: fixed;
			top: 0;
			width: 100%;
		}

		html.m-has_dialog & { //stylelint-disable-line
			overflow-y: scroll; // we need a wrapper to handle scroll / no scroll content width bumping
		}

		&.m-no_menu {
			background-color: $color-bg;
			margin-top: -61px;
			padding-top: 61px;
			z-index: -1;

			.b-header_stuck &,
			.b-header_slim & {
				z-index: z(menu-bar);
			}
		}
	}

	@include media(lg) {
		background-color: $color-white;
		margin-top: -40px;
		padding-top: 40px;

		&.m-no_menu {
			margin-top: -54px;
			padding-top: 54px;
		}

		.b-header_slim &,
		.b-header_stuck & {
			margin-top: 0;
		}
	}

	&-wrapper {
		@include media(md-down) {
			@include g-dialog_backdrop;
		}
	}

	&-inner {
		@include media(md-down) {
			background-color: $color-bg-panel;
			bottom: 0;
			height: 100%;
			left: 0;
			max-width: 400px;
			overflow: hidden;
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			transition-property: transform;
			visibility: hidden;
			width: 87vw;
			z-index: z(modal);

			&.m-closed {
				transition: $motion-ease-popups;
			}

			&.m-opened {
				box-shadow: $depth-3;
				transform: translateX(0);
				transition: $motion-ease-popups;
				visibility: visible;
			}

			&.m-no_transition {
				transition: none !important; // stylelint-disable-line
			}
		}
	}

	&-head {
		display: none;

		@include media(md-down) {
			align-items: center;
			background-color: $color-bg-shade;
			border-bottom: 1px solid $color-divider;
			display: flex;
			justify-content: space-between;
			min-height: 58px;
			padding: 6px 15px;
		}
	}

	&-title {
		color: $color-title;
		font-size: 17px;
		font-weight: 600;

		&.m-back {
			&::before {
				border: solid $color-title;
				border-width: 0 2px 2px 0;
				content: '';
				display: inline-block;
				height: 10px;
				margin-right: 5px;
				transform: rotate(135deg);
				vertical-align: middle;
				width: 10px;
			}
		}
	}

	&-category_wrapp {
		@include media(lg-up) {
			display: none;
		}
	}

	&-category {
		color: $color-primary;
		font-weight: 600;
	}

	&-aditional_links {
		background-color: $color-bg-shade;

		@include media(lg-up) {
			display: none;
		}
	}

	&-footer {
		display: none;

		@include media(md-down) {
			display: block;
		}
	}

	&-close {
		@include g-button_iconed;

		margin-left: auto;
	}

	&-link {
		@include media(md-down) {
			@include g-link_hamburger;

			color: $color-primary;
		}
	}
}
