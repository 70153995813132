.b-menu_item {
	&-link {
		align-items: center;
		color: $color-primary;
		display: inline-flex;
		font-size: 13px;
		gap: 2px;
		line-height: 18px;
		padding: 10px;
		position: relative;
		text-decoration: none;
		width: 100%;

		@include media(md-down) {
			@include g-link_hamburger;
		}

		@include hover-supported {
			&:hover {
				background-color: $color-bg-shade;
			}
		}

		&[aria-expanded='true'] {
			background-color: $color-bg-shade;
		}

		&.m-regular {
			width: 100%;
		}

		&.m-has-submenu {
			&::after {
				border: solid $color-primary;
				border-width: 0 1px 1px 0;
				content: '';
				display: inline-block;
				height: 6px;
				margin-left: auto;
				transform: rotate(-45deg);
				vertical-align: middle;
				width: 6px;

				@include media(md-down) {
					border-width: 0 2px 2px 0;
					height: 10px;
					margin-left: auto;
					margin-right: 5px;
					width: 10px;
				}
			}
		}

		&.m-highlight {
			color: $color-accent;

			&::after {
				border-color: $color-accent;
			}
		}
	}

	&-submenu {
		background-color: $color-white;
		border-left: $global-line solid $color-border-grey;
		border-right: $global-line solid $color-border-grey;
		display: none;
		font-weight: 300;
		height: 100%;
		left: 100%;
		line-height: 32px;
		position: absolute;
		top: 0;
		width: 100%;

		&[aria-hidden='false'] {
			display: block;
		}
	}

	/* stylelint-disable */
	@include media(lg-up) {
		.m-columns_view & {
			&-link {
				margin-bottom: 9px;
				padding: 0;

				@include hover-supported {
					&:hover {
						background: none;
					}
				}

				&[aria-expanded='true'] {
					background: none;
				}

				&.m-level_2 {
					color: $color-primary;
					font-weight: 600;
				}

				&.m-highlight {
					color: $color-accent;
				}

				&.m-has-submenu {
					&::after {
						content: none;
					}
				}
			}

			&-submenu {
				background: none;
				border: 0;
				display: block;
				line-height: 18px;
				position: static;

				&.m-level_4_content {
					display: none;
				}
			}
		}
	}
	/* stylelint-enable */
}
