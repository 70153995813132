/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link(inherit);
}
```

*/
@mixin g-link($_hover_color: $color-action) {
	cursor: pointer;
	text-decoration: underline;

	@include hover-supported {
		&:hover {
			color: $_hover_color;
		}
	}
}
