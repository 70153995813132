/*md

# g-loader

Designed to provide same styles of loading indicator across different components.

## Usage

```scss
.component {
	@include g-loader;
}
```

*/
@mixin g-loader() {
	&::before,
	&::after {
		bottom: 0;
		content: '';
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity $motion-fast;
	}

	&::before {
		background-color: $color-loader-bg;
	}

	&::after {
		animation: loader 1.1s infinite ease;
		border-radius: 50%;
		display: block;
		font-size: 10px;
		height: 1em;
		margin: auto;
		transform: translateZ(0);
		width: 1em;
	}

	&[aria-busy='true'] {
		cursor: wait;
		pointer-events: none;

		&::before,
		&::after {
			opacity: 1;
		}
	}
}
